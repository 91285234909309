<template>
  <div class="grid grid-cols-1 md:grid-cols-2">
    <div
      class="h-full text-white px-4 lg:px-12 xl:px-24
      pb-20 donation-modal-bg-img flex flex-col items-center pt-16  "
    >
      <div>
        <svg width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M38.0368 1.2108C39.2258 2.13532 39.4405 3.84877 38.5159 5.03788L17.3115 32.3105C16.8392 32.9178 16.1325 33.2977 15.3653 33.3561C14.5982 33.4145 13.8421 33.1461 13.2834 32.617L1.76048 21.708C0.666681 20.6725 0.619445 18.9463 1.65498 17.8525C2.69051 16.7587 4.41668 16.7115 5.51048 17.747L14.8505 26.5894L34.2096 1.68987C35.1341 0.500761 36.8477 0.286271 38.0368 1.2108Z" fill="white"/>
        </svg>
      </div>
      <div class="text-center">
        <h1 class="text-5xl font-bold mt-11 font-roboto"  id="donate-page-cc-thanks">
          <static-string>Success!</static-string>
        </h1>
      </div>

      <div class="text-center">
        <p class="font-roboto text-xl font-bold mt-6 w-2/3 mx-auto">
          <static-string>Thank you for making a difference in the world and supporting human rights.</static-string> 
        </p>
      </div>

      <div class="flex flex-col mt-auto max-w-md mx-auto pt-12">
        <div class="flex flex-col md:flex-row mb-8">
          <img :src="mailIcon" alt="Mail icon" class="md:mr-11 w-8 h-8 mx-auto md:ml-0" />
          <span class="font-lato text-lg font-bold"> {{ _('Check you emails for the receipt! ')}}</span>
        </div>

        <div class="flex flex-col md:flex-row mb-8">
          <img
            :src="roundedSuccessIcon"
            alt="Rounded success icon"
            class="md:mr-11 w-8 h-8 mx-auto md:ml-0"
          />
          <span class="font-lato text-lg font-bold">
            {{ _(' Tax information: If you need a tax refund declaration, please contact us at info@liberties.eu') }}
          </span>
        </div>
      </div>
    </div>
    <div class="h-full pt-10 bg-gray-100 text-black px-4 lg:px-12 xl:px-24 flex flex-col">
      <div class="subscribe-form stripe-thank-you-page" v-html="newsletterHTML" id="donate-checkoutpage-thanks-nwslttr-btn"></div>

      <SocialMedias />
      <SafetyItems />
    </div>
  </div>
</template>

<script>
import successIcon from '../../../images/icons/success.svg';
import mailIcon from '../../../images/icons/mailIcon.svg';
import roundedSuccessIcon from '../../../images/icons/roundedSuccessIcon.svg';
import SocialMedias from './SocialMedias.vue';
import SafetyItems from './SafetyItems.vue';
import HandleI18n from '../Utils/HandleI18n';

export default {
  mixins: [HandleI18n],

  components: { SocialMedias, SafetyItems },

  props: {
    newsletterHTML: { required: false, type: String },
    donation: { required: true, type: Object }
  },

  data() {
    return {
      icon: successIcon,
      mailIcon: mailIcon,
      roundedSuccessIcon: roundedSuccessIcon
    };
  },

  mounted() {
    let donationInterval = ' ';

    if (this.donation.interval == 'once') {
      donationInterval = 'oneoff-stripe';
    }
    else {
      donationInterval = 'monthly-stripe';
    }

    _mtm.push({
      'event': 'mtm_event',
      'event_category': 'Donations',
      'event_action':donationInterval,
      'event_name': this.donation.campaign.title,
      'event_value': `${ this.donation.amount }`
      });
  }
};
</script>

<style lang="scss" scoped>
::v-deep .subscribe-form .newsletter-checkbox {
  @apply text-black;
}
</style>
