<template>
  <div class="flex flex-col h-full px-4 lg:px-12 xl:px-24 bg-gray-100 pt-24 pb-24">
    <h3 class="mb-10 font-roboto font-bold text-2xl sm:text-3xl">
      <static-string>Choose Your Payment Method</static-string> 
    </h3>

    <div class=" hover:bg-white hover:cursor-pointer py-3 px-3">
      <PayPalButton :donation="donation" class="paypal-btn" id="donate-checkoutpage-btn-pp" />
    </div>

    <h2 class="w-full relative text-center border-b-4 leading-none border-black mb-10">
      <span class="absolute bg-gray-100 font-bold px-5 -mt-2 or-on-line"> <static-string>OR</static-string></span>
    </h2>

    <div v-if="apiErrors" class="my-2 text-red text-sm">
      <div v-for="error in apiErrors" :key="error">
        {{ error }}
      </div>
    </div>

    <div class="hover:bg-white px-4 py-6 text-white mb-8"
        :class="{
          'bg-white': cardFocused
        }"
    >
      <div class="grid gap-y-0 text-black">
        <input
          v-model="model.name"
          class="h-16 px-4 z-50 rounded outline-none border border-black border-solid border-b-0 focus:border focus:border-black focus:ring-4 focus:ring-black focus:ring-opacity-60"
          type="text"
          placeholder="Name"
          name="name"
        />

        <input
          v-model="model.email"
          class="h-16 px-4 z-50 rounded outline-none border border-black border-solid border-b-0 focus:border focus:border-black focus:ring-4 focus:ring-black focus:ring-opacity-60"
          type="email"
          placeholder="Email"
          name="email"
        />

        <div>
          <div
            class="h-16 bg-white text-black flex items-center rounded space-x-4 border border-solid"
            :class="{
              'border-black ring-4 ring-black ring-opacity-60': cardFocused,
              'border-black': !cardFocused
            }"
          >
            <div id="card-wrapper" class="w-full px-4"></div>
          </div>

          <div v-if="cardError" class="mt-2 text-red text-sm">
            {{ cardError }}
          </div>
        </div>
      </div>

      <div class="mt-10">
        <label class="container-label text-base sm:text-lg text-black">
          <input type="checkbox" v-model="model.consent" />
          <span class="checkmark"></span>
          <!-- TODO Make this change depending on the current language -->
          <static-string> I accept Liberties'</static-string>

          <a href="/en/about/privacy-policy" class="underline">
            <static-string>Privacy Policy. </static-string>
          </a>
          <span class="has-link"> </span>

          <div v-if="errors.length" class="mt-2 text-red text-sm">
            <static-string> You must accept the terms. </static-string>
          </div>
        </label>
      </div>

      <div class="mt-4">
        <label class="container-label text-base sm:text-lg text-black">
          <input type="checkbox" v-model="model.emailConsent" />
          <span class="checkmark"></span>
          <static-string> I consent to be contacted by Liberties via email.</static-string>
        </label>
      </div>

      <VueRecaptcha
        ref="recaptcha"
        :sitekey="reCaptchaSiteKey"
        size="invisible"
        @verify="setRecaptchaResultAndSubmit"
      />

      <div class="w-full">
        <div class="buttons mt-10">
          <button
            @click="executeRecaptcha"
            class="donation-buttons -payment bg-orange text-white h-16 whitespace-nowrap w-full xl:w-auto"
            :class="{ 'opacity-40': isLoading }"
            id="donate-checkoutpage-btn-cc"
          >
            <span v-if="isLoading">
              <static-string>Loading...</static-string>
            </span>
            <span v-else>
              <static-string>Pay With Credit Card</static-string>
            </span>
          </button>
        </div>
      </div>
    </div>

    <h3 class="font-bold text-xl text-black font-roboto mt-9 mb-4 text-center capitalize">
      <static-string>We are keeping you safe!</static-string>
    </h3>

    <SafetyItems />
  </div>
</template>

<script>
/* globals Stripe */
import VueRecaptcha from 'vue-recaptcha';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import PayPalButton from './PayPalButton.vue';
import SafetyItems from './SafetyItems.vue';
import HandleI18n from '../Utils/HandleI18n';

export default {
  inject: ['stripePublicKey', 'reCaptchaSiteKey'],
  mixins: [HandleI18n],
  components: { VueRecaptcha, PayPalButton, SafetyItems },

  props: {
    donation: { required: true, type: Object }
  },

  data() {
    // We want only the campaign ID in the model object
    const cleanDonationObject = { ...this.donation, campaign: this.donation.campaign.id };

    return {
      errors: [],
      model: {
        ...cleanDonationObject,
        name: '',
        email: '',
        concent: false,
        emailConsent: false
      },

      recatpchaResponse: null,
      isLoading: false,
      apiErrors: null,
      cardError: null,
      cardFocused: false
    };
  },

  mounted() {
    this.initStripe();

    this.axios = axios.create({
      headers: { 'X-Custom-Header': 'foobar' }
    });

    let modalCloseBtn = document.querySelector('button.absolute.top-0');
    modalCloseBtn.classList.add('donation-close-btn');
  },

  beforeDestroy() {
    this.card.unmount();
  },

  computed: {
    isValid() {
      return !this.isInvalid;
    },

    isInvalid() {
      return (
        !this.model.consent ||
        isEmpty(this.model.name) ||
        isEmpty(this.model.email) ||
        !isEmpty(this.cardError)
      );
    }
  },

  methods: {
    initStripe() {
      this.stripe = new Stripe(this.stripePublicKey);

      this.card = this.stripe.elements().create('card', {
        hidePostalCode: true,
        style: {
          base: {
            color: '#000',
            fontWeight: 400,
            fontFamily: '"Lato", sans-serif',
            fontSize: '16px',

            '::placeholder': {
              color: '#a1a1aa'
            }
          }
        }
      });

      this.card.mount('#card-wrapper');

      this.card.on('change', this.handleStripeError);
      this.card.on('focus', () => (this.cardFocused = true));
      this.card.on('blur', () => (this.cardFocused = false));
    },

    executeRecaptcha() {
      if (!this.model.consent) {
        this.errors.push('You must accept the terms');
        return;
      }

      this.$refs.recaptcha.execute();
    },

    setRecaptchaResultAndSubmit(response) {
      this.recatpchaResponse = response;
      
      this.submit();
    },

    submit() {
      this.isLoading = true;

      this.stripe.createPaymentMethod({ type: 'card', card: this.card }).then((result) => {
        this.isLoading = false;

        this.handleStripeError(result);
        this.handleStripeSuccess(result);
      });
    },

    handleStripeSuccess(result) {
      if (!result.paymentMethod || !result.paymentMethod.id) return;

      this.sendPaymentRequestToServer({
        ...this.model,
        email_consent: this.model.emailConsent,
        token: result.paymentMethod.id
      });
    },

    handleStripeError(result) {
      this.cardError = null;
      if (result.error) this.cardError = result.error.message;
    },

    handleStripeRequiresInteraction(secret, isSubscription) {
      var method = isSubscription ? 'confirmCardPayment' : 'handleCardAction';

      this.stripe[method](secret).then((response) => {
        if (response.error) return displayApiErrors();

        // The card action has been handled
        // The PaymentIntent can be confirmed again on the server
        this.sendPaymentRequestToServer({
          ...this.model,
          payment_intent: response.paymentIntent.id
        });
      });
    },

    async sendPaymentRequestToServer(params) {
      this.isLoading = true;

      try {
        let { data } = await axios({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
          },
          url: '/en/donate',
          method: 'POST',
          data: {
            'g-recaptcha-response-data': this.recatpchaResponse,
            donation: params
          }
        });

        if (data.status === 'paid') {
          this.$emit('completed');
        } else if (data.status === 'require_interaction') {
          let isSubscription = params.interval !== 'once';
          this.handleStripeRequiresInteraction(data.secret, isSubscription);
        } else {
          this.displayApiErrors(data.errors);
        }
      } catch (e) {
        this.displayApiErrors(e.response.data.errors);
      }

      this.isLoading = false;
    },

    displayApiErrors(errors) {
      this.apiErrors = errors || ['Something went wrong. Please, try again later.'];
      this.$refs.recaptcha.reset();
    },

    _(key) {
      return this.i18n[key];
    }
  }
};
</script>

<style lang="scss">
.has-link a {
  text-decoration: underline;
}
.or-on-line {
  left: 50%;
  margin-left: -2rem;
}
</style>
